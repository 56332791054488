import type {ExtraLanguageContents} from '@smart/website-extra-language-contents';
import type {PageModel} from '@smart/website-page-model';
import React from 'react';

export function useShouldLoadMiniCart(
  extraLanguageContents: ExtraLanguageContents | undefined,
  contents: PageModel['contents'],
  showBottomBar: boolean,
): boolean {
  return React.useMemo(() => {
    if (!extraLanguageContents || !extraLanguageContents?.miniCartFeatureApp) {
      return false;
    }

    const {connectedFeatureApps} =
      extraLanguageContents?.miniCartFeatureApp || {};
    const needAddCartFeatureAppsArray: string[] =
      (connectedFeatureApps as string[]) || [];

    return (
      showBottomBar ||
      contents.some(
        (content) =>
          content.type === `feature-app` &&
          needAddCartFeatureAppsArray.includes(content.featureAppName),
      )
    );
  }, [extraLanguageContents, contents, showBottomBar]);
}
