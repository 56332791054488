import type {ExtraLanguageContents} from '@smart/website-extra-language-contents';

export interface FetchExtraLanguageContentsOptions {
  readonly language: string;
  readonly signal: AbortSignal;
}

export async function fetchExtraLanguageContents(
  options: FetchExtraLanguageContentsOptions,
): Promise<ExtraLanguageContents | undefined> {
  const {language, signal} = options;
  const url = `${process.env.BASE_URL}/extra-contents?language=${language}`;
  const response = await fetch(url, {
    signal,
    mode: `no-cors`,
    credentials: `include`,
  });

  return response.ok
    ? (response.json() as Promise<ExtraLanguageContents>)
    : undefined;
}
