import type {FeatureAppContent} from '@smart/website-extra-language-contents';
import * as React from 'react';
import {FeatureAppLoader} from './feature-app-loader';

export interface MiniCartFeatureAppLoaderProps {
  readonly content: FeatureAppContent;
}

export const MiniCartFeatureAppLoader = React.memo(
  ({content}: MiniCartFeatureAppLoaderProps) => {
    const {baseUrl, src} = content;

    return (
      <FeatureAppLoader
        featureAppId="mini-cart-app"
        baseUrl={baseUrl}
        src={src}
      />
    );
  },
  (prevProps, nextProps) => prevProps.content.id === nextProps.content.id,
);

MiniCartFeatureAppLoader.displayName = `MiniCartFeatureAppLoader`;
