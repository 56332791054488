import {useEffect, useState} from 'react';

function getBrowserName():
  | 'Unknown'
  | 'Edge'
  | 'Chrome'
  | 'Firefox'
  | 'Safari'
  | 'Opera' {
  const userAgent = navigator.userAgent;
  let browserName:
    | 'Unknown'
    | 'Edge'
    | 'Chrome'
    | 'Firefox'
    | 'Safari'
    | 'Opera' = `Unknown`;

  if (userAgent.indexOf(`Edge`) > -1) {
    browserName = `Edge`;
  } else if (
    userAgent.indexOf(`Chrome`) > -1 &&
    userAgent.indexOf(`Edg`) === -1
  ) {
    browserName = `Chrome`;
  } else if (
    userAgent.indexOf(`Safari`) > -1 &&
    userAgent.indexOf(`Chrome`) === -1
  ) {
    browserName = `Safari`;
  } else if (userAgent.indexOf(`Firefox`) > -1) {
    browserName = `Firefox`;
  } else if (userAgent.indexOf(`Opera`) > -1 || userAgent.indexOf(`OPR`) > -1) {
    browserName = `Opera`;
  }

  return browserName;
}

export function useBrowserInfo() {
  const [browserInfo, setBrowserInfo] = useState<
    'Unknown' | 'Edge' | 'Chrome' | 'Firefox' | 'Safari' | 'Opera'
  >(getBrowserName());

  useEffect(() => {
    setBrowserInfo(getBrowserName());
  }, []);

  return browserInfo;
}

export default useBrowserInfo;
